import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { FormErrors } from '../components/FormErrors';
import { StepAction } from '../components/StepController';

export function EnterRego({
  rego, regoValidation, stepInfo, loading, onChange, onValidate, onNavigate,
}) {
  const isFormValid = (regoValidation.rego ?? '') === '';

  function validateField(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'rego': {
        const isRegoValid = value.length >= 3;

        onValidate({
          [name]: isRegoValid ? '' : 'Rego needs to be at least 3 characters long',
        });
        break; }
      default:
        break;
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <TextField
          required
          label="Boat Registration Number"
          variant="standard"
          value={rego.rego}
          onChange={(e) => { validateField(e); onChange(e); }}
          name="rego"
          data-testid="input-rego"
        />
      </Stack>
      <div className="panel panel-default">
        <FormErrors formErrors={regoValidation} />
      </div>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => onNavigate(StepAction.NEXT, stepInfo.postEvent)}
            sx={{ mt: 1, mr: 1 }}
            disabled={!isFormValid || loading}
            data-testid="button-rego-continue"
          >
            Continue
          </Button>
          {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '90%',
              marginTop: '-8px',
              marginLeft: '-12px',
            }}
          />
          )}
        </div>
      </Box>

    </>
  );
}
export default EnterRego;
