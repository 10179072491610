import React from 'react';

export function FormErrors({ formErrors }) {
  return (
    <div className="formErrors">
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0 && fieldName !== 'verificationStatus') {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>{formErrors[fieldName]}</p>
          );
        }
        return '';
      })}
    </div>
  );
}
