import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormErrors } from '../components/FormErrors';
import { StepAction } from '../components/StepController';

export const BoatTypes = [
  'Cruiser/Flybridge',
  'Half Cabin',
  'Houseboat',
  'Jet Ski',
  'Kayak',
  'Power Boat',
  'Power Cat',
  'Rib',
  'Multi-Hull - Sail',
  'Sail Board',
  'Cat Power',
  'Tinny',
  'Yacht',
  'Bowrider',
  'Other',
];

export const BoatColours = [
  'Aluminium',
  'White',
  'Black',
  'Grey',
  'Green',
  'Blue',
  'Red',
  'Yellow',
  'Orange',
  'Purple',
];

function VesselDetails({
  boat, boatValidation, stepInfo, onChange, onValidate, onNavigate,
}) {
  const isFormValid = (boatValidation.vesselType ?? '') === '' && (boatValidation.vesselSize ?? '') === '';

  function validateField(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'vesselType': {
        const isVesselTypeValid = value !== '';

        onValidate({
          [name]: isVesselTypeValid ? '' : 'Vessel type needs to be selected',
        });
        break;
      }
      case 'vesselSize': {
        const isVesselSizeValid = value > 0 && /\d+/.test(value);

        onValidate({
          [name]: isVesselSizeValid ? '' : 'Vessel size needs to be a number greater than 0',
        });
        break;
      }
      default:
        break;
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <TextField
          id="boat-name"
          label="Boat Name"
          variant="outlined"
          name="name"
          value={boat.name}
          onChange={(e) => { validateField(e); onChange(e); }}
          data-testid="input-vessel-name"
        />
        <InputLabel id="vessel-type-select-label">Vessel Type</InputLabel>
        <Select
          id="vessel-type-select"
          value={boat.vesselType}
          onChange={(e) => { validateField(e); onChange(e); }}
          name="vesselType"
          data-testid="input-vessel-type"
        >
          {BoatTypes.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>

        <InputLabel id="vessel-size-select-label">Vessel Size</InputLabel>
        <FormGroup row>
          <TextField
            required
            id="vessel-size"
            type="number"
            value={boat.vesselSize}
            onChange={(e) => { validateField(e); onChange(e); }}
            name="vesselSize"
            data-testid="input-vessel-size"
          />
          <RadioGroup
            row
            aria-labelledby="vessel-size-unit-label"
            defaultValue="feet"
            value={boat.sizeUnit}
            onChange={(e) => { validateField(e); onChange(e); }}
            name="sizeUnit"
            data-testid="input-vessel-unit"
          >
            <FormControlLabel value="feet" control={<Radio />} label="feet" />
            <FormControlLabel value="meters" control={<Radio />} label="meters" />
          </RadioGroup>
        </FormGroup>

        <InputLabel id="hull-colour-select-label">Hull Colour</InputLabel>
        <Select
          id="hull-colour-select"
          value={boat.hullColour}
          onChange={(e) => { validateField(e); onChange(e); }}
          name="hullColour"
          data-testid="input-vessel-colour"
        >
          {BoatColours.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <div className="panel panel-default">
        <FormErrors formErrors={boatValidation} />
      </div>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => onNavigate(StepAction.NEXT, stepInfo.postEvent)}
            sx={{ mt: 1, mr: 1 }}
            disabled={!isFormValid}
            data-testid="button-vessel-continue"
          >
            Continue
          </Button>
          <Button
            onClick={() => onNavigate(StepAction.PREV, () => { })}
            sx={{ mt: 1, mr: 1 }}
            data-testid="button-vessel-back"
          >
            Back
          </Button>
        </div>
      </Box>
    </>

  );
}

export default VesselDetails;
