import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { FormErrors } from '../components/FormErrors';
import { StepAction } from '../components/StepController';
import { validateCaptcha } from '../common';

function ConfirmMobile({
  mobile, mobileValidation, requestToken, stepInfo, onChange, onValidate, onNavigate,
}) {
  const [verifyButtonText, setVerifyButtonText] = React.useState('Send Verification Code');
  const [verificationCode, setVerificationCode] = React.useState('');
  const [verificationError, setVerificationError] = React.useState({ code: '' });
  const [loading, setLoading] = React.useState(false);

  function validateField(e) {
    const { name, value } = e.target;
    const pattern = /^04[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/g;
    switch (name) {
      case 'newMobile':
        onValidate({
          [name]: pattern.test(value) ? '' : 'Mobile needs to be in the format 04xx xxx xxx',
        });
        break;
      default:
        break;
    }
  }

  function onChangeVerificationCode(e) {
    const { value } = e.target;
    setVerificationCode(value);
  }

  function resetVerification() {
    setVerifyButtonText('Send Verification Code');
    onValidate({
      verificationStatus: 'START',
    });
  }

  async function sendVerificationCode() {
    setLoading(true);
    const token = await validateCaptcha();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MERL_API}/mobile/send-verify`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({
            tkn: requestToken,
            gr: token,
            newMobile: mobile.newMobile,
          }),
        },
      );
      const json = await response.json();

      if (json.success) {
        setVerifyButtonText('Re-send Verification Code');
        onValidate({
          verificationStatus: 'SENT',
        });
      }
    } catch (err) {
      setVerifyButtonText('Re-send Verification Code');
    }

    setLoading(false);
  }

  async function verifyCode() {
    setLoading(true);

    const token = await validateCaptcha();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MERL_API}/mobile/verify`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({
            tkn: requestToken,
            gr: token,
            code: verificationCode,
          }),
        },
      );
      const json = await response.json();

      if (!json.success) {
        throw new Error('Invalid captcha');
      }
    } catch (err) {
      setVerificationError((prevState) => ({
        ...prevState,
        code: 'invalid',
      }));
      setLoading(false);
      return false;
    }

    onNavigate(StepAction.NEXT, stepInfo.postEvent);
    setLoading(false);
    return true;
  }
  return (
    <>
      <Stack spacing={2}>
        <FormControl component="fieldset">
          {(mobileValidation.verificationStatus !== 'SENT')
                        && (
                        <TextField
                          required
                          id="mobile-number"
                          label="Mobile Number"
                          placeholder="04__ ___ ___"
                          value={mobile.newMobile}
                          variant="standard"
                          onChange={(e) => { validateField(e); onChange(e); }}
                          name="newMobile"
                          data-testid="input-mobile"
                        />
                        )}
          <div className="panel panel-default">
            <FormErrors formErrors={mobileValidation} />
          </div>
        </FormControl>
        <FormControl component="fieldset">
          <>
            {(mobileValidation.verificationStatus === 'SENT') && <Typography>Please enter the 6 digit code sent to your mobile to continue</Typography>}
            {(mobileValidation.verificationStatus === 'SENT') && (
              <TextField
                required
                id="verification-code"
                label="Verification Code"
                variant="outlined"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => { onChangeVerificationCode(e); }}
                inputMode="numeric"
                autoComplete="one-time-code"
                data-testid="input-mobile-verify"
              />
            )}
          </>
        </FormControl>
      </Stack>
      <div className="panel panel-default">
        <FormErrors formErrors={verificationError} />
      </div>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <div>
          {(mobileValidation.verificationStatus !== 'SENT') && (
            <Button
              variant="contained"
              color="success"
              onClick={async () => sendVerificationCode()}
              sx={{ mt: 1, mr: 1 }}
              disabled={loading}
              data-testid="button-mobile-verify"
            >
              {verifyButtonText}
            </Button>
          )}
          <Button
            variant="contained"
            color="success"
            onClick={(e) => { verifyCode(e); }}
            sx={{ mt: 1, mr: 1 }}
            disabled={mobileValidation.verificationStatus !== 'SENT' || loading}
            data-testid="button-mobile-continue"
          >
            Continue
          </Button>
          {(mobileValidation.verificationStatus === 'SENT') && (
            <Button
              variant="outlined"
              onClick={() => resetVerification()}
              sx={{ mt: 1, mr: 1 }}
              disabled={loading}
              data-testid="button-mobile-differentmobile"
            >
              Use different mobile
            </Button>
          )}
          <Button
            onClick={() => onNavigate(StepAction.PREV, () => { })}
            sx={{ mt: 1, mr: 1 }}
            disabled={loading}
            data-testid="button-mobile-back"
          >
            Back
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '90%',
                marginTop: '-8px',
                marginLeft: '-12px',
              }}
            />
          )}
        </div>
      </Box>
    </>
  );
}
export default ConfirmMobile;
