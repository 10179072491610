import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const StepName = {
  ENTER_REGO: 'Enter Registration',
  CONFIRM_MOBILE: 'Confirm Mobile',
  CONFIRM_VESSEL: 'Confirm Vessel',
  TRIP_DETAILS: 'Trip Details',
  SUMMARY: 'Summary',
};

export const StepAction = {
  NEXT: 'Next',
  PREV: 'Previous',
  RESET: 'Reset',
};

export function StepController(step, handleStep) {
  const { id, postEvent } = step;
  return (
    <Box sx={{ mb: 2 }}>
      <div>
        {id === StepName.SUMMARY
          ? (
            <Button
              variant="contained"
              onClick={() => handleStep(StepAction.RESET, () => {})}
              sx={{ mt: 1, mr: 1 }}
            >
              Finish
            </Button>
          )
          : (
            <Button
              variant="contained"
              onClick={() => handleStep(StepAction.NEXT, postEvent)}
              sx={{ mt: 1, mr: 1 }}
            >
              Continue
            </Button>
          )}
        <Button
          disabled={id === StepName.ENTER_REGO}
          onClick={() => handleStep(StepAction.PREV, () => {})}
          sx={{ mt: 1, mr: 1 }}
        >
          Back
        </Button>
      </div>
    </Box>
  );
}
