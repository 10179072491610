import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { FormErrors } from '../components/FormErrors';
import { StepAction } from '../components/StepController';

function TripDetails({
  trip, tripValidation, loading, onChange, onValidate, onNavigate,
}) {
  const isFormValid = (tripValidation.departurePoint ?? '') === ''
        && (tripValidation.destination ?? '') === ''
        && (tripValidation.pob ?? '') === '';

  function validateField(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'departurePoint': {
        const isDeparturePointValid = value !== '';

        onValidate({
          [name]: isDeparturePointValid ? '' : 'Departure Point needs to be entered',
        });
        break; }
      case 'destination': {
        const isDestinationValid = value !== '';

        onValidate({
          [name]: isDestinationValid ? '' : 'Destination needs to be entered',
        });
        break; }
      case 'pob': {
        const isPobValid = value > 0;

        onValidate({
          [name]: isPobValid ? '' : 'Persons on board needs to be greater than 0',
        });
        break; }
      default:
        break;
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <InputLabel id="departure-point-label">Departure Point</InputLabel>
        <TextField
          required
          id="departure-point"
          labelid="departure-point-label"
          variant="outlined"
          name="departurePoint"
          value={trip.departurePoint}
          onChange={(e) => { validateField(e); onChange(e); }}
          data-testid="input-trip-departure"
        />
        <InputLabel id="destination-point-label">Destination Point</InputLabel>
        <TextField
          required
          id="destination"
          labelid="destination-point-label"
          variant="outlined"
          name="destination"
          value={trip.destination}
          onChange={(e) => { validateField(e); onChange(e); }}
          data-testid="input-trip-destination"
        />
        <InputLabel id="persons-on-board-label">Persons On Board</InputLabel>
        <TextField
          required
          id="persons-on-board"
          labelid="persons-on-board-label"
          variant="outlined"
          type="number"
          name="pob"
          value={trip.pob}
          onChange={(e) => { validateField(e); onChange(e); }}
          data-testid="input-trip-pob"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel id="returnDate-label">Expected Return Date</InputLabel>
          <MobileDatePicker
            labelid="returnDate-label"
            minDate={new Date()}
            inputFormat="yyyy/MM/dd"
            mask="___/__/__"
            renderInput={(params) => <TextField {...params} />}
            value={trip.returnDate}
            name="returnDate"
            onChange={(newValue) => {
              const e = { target: { name: 'returnDate', value: newValue } };
              validateField(e);
              onChange(e);
            }}
            data-testid="input-trip-return"
          />
        </LocalizationProvider>
        <InputLabel id="expected-return-time-label">Expected Return Time</InputLabel>
        <Select
          id="expected-return-time"
          labelid="expected-return-time-label"
          name="returnTime"
          value={trip.returnTime}
          onChange={(e) => { validateField(e); onChange(e); }}
          data-testid="input-trip-time"
        >
          <MenuItem value={0}>00:00 (12:00 AM)</MenuItem>
          <MenuItem value={1}>01:00 (1:00 AM)</MenuItem>
          <MenuItem value={2}>02:00 (2:00 AM)</MenuItem>
          <MenuItem value={3}>03:00 (3:00 AM)</MenuItem>
          <MenuItem value={4}>04:00 (4:00 AM)</MenuItem>
          <MenuItem value={5}>05:00 (5:00 AM)</MenuItem>
          <MenuItem value={6}>06:00 (6:00 AM)</MenuItem>
          <MenuItem value={7}>07:00 (7:00 AM)</MenuItem>
          <MenuItem value={8}>08:00 (8:00 AM)</MenuItem>
          <MenuItem value={9}>09:00 (9:00 AM)</MenuItem>
          <MenuItem value={10}>10:00 (10:00 AM)</MenuItem>
          <MenuItem value={11}>11:00 (11:00 AM)</MenuItem>
          <MenuItem value={12}>12:00 (12:00 PM)</MenuItem>
          <MenuItem value={13}>13:00 (1:00 PM)</MenuItem>
          <MenuItem value={14}>14:00 (2:00 PM)</MenuItem>
          <MenuItem value={15}>15:00 (3:00 PM)</MenuItem>
          <MenuItem value={16}>16:00 (4:00 PM)</MenuItem>
          <MenuItem value={17}>17:00 (5:00 PM)</MenuItem>
          <MenuItem value={18}>18:00 (6:00 PM)</MenuItem>
          <MenuItem value={19}>19:00 (7:00 PM)</MenuItem>
          <MenuItem value={20}>20:00 (8:00 PM)</MenuItem>
          <MenuItem value={21}>21:00 (9:00 PM)</MenuItem>
          <MenuItem value={22}>22:00 (10:00 PM)</MenuItem>
          <MenuItem value={23}>23:00 (11:00 PM)</MenuItem>
        </Select>
      </Stack>
      <div className="panel panel-default">
        <FormErrors formErrors={tripValidation} />
      </div>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => onNavigate()}
            sx={{ mt: 1, mr: 1 }}
            disabled={!isFormValid || loading}
            data-testid="button-trip-finish"
          >
            Finish
          </Button>
          <Button
            onClick={() => onNavigate(StepAction.PREV, () => { })}
            sx={{ mt: 1, mr: 1 }}
            disabled={loading}
            data-testid="button-trip-back"
          >
            Back
          </Button>
          {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '90%',
              marginTop: '-8px',
              marginLeft: '-12px',
            }}
          />
          )}
        </div>
      </Box>
    </>

  );
}

export default TripDetails;
