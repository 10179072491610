import './App.css';

import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';
import PhoneIcon from '@mui/icons-material/Smartphone';
import RadioIcon from '@mui/icons-material/CellTower';
import { validateCaptcha } from './common';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function Logout() {
  const [tripData, setTripData] = React.useState({});
  const [alertData, setAlertData] = React.useState({
    open: false,
    message: '',
  });

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertData({ open: false, message: '' });
  };

  const alertAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleAlertClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  async function LogOff() {
    try {
      const params = new URLSearchParams(window.location.search);
      const tkn = params.get('tkn');
      const gr = await validateCaptcha();

      const response = await fetch(
        `${process.env.REACT_APP_MERL_API}/trip/logoff`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({
            tkn,
            gr,
          }),
        },
      );
      const json = await response.json();
      setTripData({
        ...json,
        niceDate: intervalToDuration({
          start: parseISO(json.eta),
          end: new Date(),
        }),
      });
      return true;
    } catch (err) {
      setAlertData({
        open: true,
        message: 'Problem talking to backend service',
      });
    }
  }

  React.useEffect(() => {
    async function GetData() {
      try {
        const params = new URLSearchParams(window.location.search);
        const tkn = params.get('tkn');
        const response = await fetch(
          `${process.env.REACT_APP_MERL_API}/trip?tkn=${tkn}`,
        );
        const json = await response.json();
        setTripData({
          ...json,
          niceDate: formatDuration(intervalToDuration({
            start: new Date(),
            end: parseISO(json.eta),
          }), { format: ['days', 'hours', 'minutes'] }),
        });
        return true;
      } catch (err) {
        setAlertData({
          open: true,
          message: 'Problem talking to backend service',
        });
      }
    }
    GetData();
  }, []);

  return (
    <>
      <Typography component="h2" variant="h4" align="center">
        Your trip has been registered
      </Typography>
      {tripData.status === 'ACTIVE' && (
      <MuiAlert variant="filled" severity="warning">
        Please remember to log off when you are safely back from your trip
      </MuiAlert>
      )}
      <Typography my={2} mx={5}>
        We will send you a reminder SMS 15 minutes after your expected return if
        you have not logged off already
      </Typography>
      {tripData.status === 'ACTIVE' && parseISO(tripData.eta) >= new Date() && (
      <MuiAlert severity="success">
        <AlertTitle>{tripData.status}</AlertTitle>
        Your trip is currently active
        <Typography>
          We are expecting you back in
          {' '}
          {tripData.niceDate}
        </Typography>
      </MuiAlert>
      )}
      {tripData.status === 'ACTIVE' && parseISO(tripData.eta) < new Date() && (
      <MuiAlert severity="warning">
        <AlertTitle>OVERDUE</AlertTitle>
        You are overdue by
        {' '}
        {tripData.niceDate}
      </MuiAlert>
      )}
      {tripData.status === 'COMPLETED' && (
      <MuiAlert severity="info">
        <AlertTitle>{tripData.status}</AlertTitle>
        This trip has been completed and logged off
      </MuiAlert>
      )}
      {tripData.status !== 'COMPLETED' && (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button variant="contained" onClick={() => { LogOff(); }} sx={{ mt: 1, mr: 1 }}>
          Click here to logoff
        </Button>
      </Box>
      )}
      <Box mt={2}>
        <MuiAlert severity="info">
          <AlertTitle>CONTACT</AlertTitle>
          The radio room can also be contacted using the following

          <Stack direction="row" alignItems="center" gap={1}>
            <PhoneIcon />
            <Typography>+61 7 3821 2244</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <RadioIcon />
            <Typography>VHF 73/81/82</Typography>
          </Stack>
        </MuiAlert>
      </Box>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alertData.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          action={alertAction}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Logout;
