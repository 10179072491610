import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function TripDetails() {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Finished
      </Typography>
    </Stack>
  );
}
export default TripDetails;
