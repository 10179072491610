import { SITE_KEY } from './constants';

export function validateCaptcha() {
  return new Promise((res) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, {
        action:
                    'submit',
      }).then((token) => res(token));
    });
  });
}
