import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { addDays, format } from 'date-fns';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EnterRego from './steps/EnterRego';
import ConfirmMobile from './steps/ConfirmMobile';
import VesselDetails, { BoatColours, BoatTypes } from './steps/VesselDetails';
import TripDetails from './steps/TripDetails';
import Finished from './steps/Finished';
import { StepName, StepAction } from './components/StepController';
import { validateCaptcha } from './common';

const crypto = require('crypto');

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function Register() {
  const navigate = useNavigate();
  const [requestToken] = React.useState(crypto.randomBytes(8).toString('hex'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [alertData, setAlertData] = React.useState({
    open: false,
    message: '',
  });
  const [loading, setLoading] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertData({ open: false, message: '' });
  };

  const alertAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleAlertClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  /// Rego Screen
  const [regoData, setRegoData] = React.useState({ rego: '' });
  const handleRegoChange = (e) => {
    const { name, value } = e.target;
    setRegoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [regoValidation, setRegoValidation] = React.useState({
    rego: ' needs to be at least 3 characters long',
  });
  const handleRegoValidation = (errors) => {
    setRegoValidation((prevState) => ({
      ...prevState,
      ...errors,
    }));
  };

  /// Mobile Screen
  const [mobileData, setMobileData] = React.useState({
    savedMobile: '',
    newMobile: '',
    useSavedMobile: false,
    alternateMobile: '',
    useAlternateMobile: true,
  });
  const handleMobileChange = (e) => {
    const { value } = e.target;
    setMobileData((prevState) => ({
      ...prevState,
      newMobile: value,
    }));
  };

  const [mobileValidation, setMobileValidation] = React.useState({
    isVerified: false,
    verificationStatus: 'START',
    newMobile: 'Mobile needs to be in the format 04xx xxx xxx',
  });
  const handleMobileValidation = (errors) => {
    setMobileValidation((prevState) => ({
      ...prevState,
      ...errors,
    }));
  };

  /// Boat Screen
  const [boatData, setBoatData] = React.useState({
    name: '',
    vesselType: 'Yacht',
    vesselSize: 0,
    sizeUnit: 'feet',
    hullColour: 'White',
  });
  const handleBoatChange = (e) => {
    const { name, value } = e.target;
    setBoatData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [boatValidation, setBoatValidation] = React.useState({
    vesselType: '',
    vesselSize: '',
  });
  const handleBoatValidation = (errors) => {
    setBoatValidation((prevState) => ({
      ...prevState,
      ...errors,
    }));
  };

  /// Trip Screen
  const params = new URLSearchParams(window.location.search);
  const location = params.get('loc') ?? undefined;
  const [tripData, setTripData] = React.useState({
    departurePoint: location ?? '',
    destination: '',
    pob: 1,
    returnDate: ((new Date()).getHours() + 3) > 23 ? addDays(new Date(), 1) : new Date(),
    returnTime: ((new Date()).getHours() + 3) % 24,
  });
  const handleTripChange = (e) => {
    const { name, value } = e.target;
    setTripData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [tripValidation, setTripValidation] = React.useState({
    departurePoint: !location ? 'Departure Point needs to be entered' : '',
    destination: 'Destination needs to be entered',
    pob: '',
  });
  const handleTripValidation = (errors) => {
    setTripValidation((prevState) => ({
      ...prevState,
      ...errors,
    }));
  };
  useEffect(() => {}, [
    tripValidation,
    tripData,
    boatValidation,
    boatData,
    mobileValidation,
    mobileData,
    regoValidation,
    regoData,
  ]);

  const fnLoadBoatDetails = async () => {
    if (regoData.rego && regoData.rego !== '') {
      const captcha = await validateCaptcha();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MERL_API}/boat/find?rego=${regoData.rego}&gr=${captcha}&token=${requestToken}`,
        );
        const json = await response.json();
        const sizeRegex = /^(\d*).*(m|ft)/g;
        const boatSizeInfo = sizeRegex.exec(json[0]?.boat_length) ?? [];
        const boatType = BoatTypes.includes(json[0]?.boat_type) ? json[0]?.boat_type : 'Kayak';
        const boatColour = BoatColours.includes(json[0]?.boat_colour) ? json[0]?.boat_colour : 'White';

        setBoatData((prevState) => ({
          ...prevState,
          name: json[0]?.boatname,
          vesselType: boatType,
          hullColour: boatColour,
          vesselSize: boatSizeInfo[1] ?? 3,
          sizeUnit: boatSizeInfo[2] === 'ft' ? 'feet' : 'meters',
        }));
        return true;
      } catch (err) {
        setAlertData({
          open: true,
          message: `Problem talking to backend service: ${err}`,
        });
      }
    }
    return false;
  };

  const fnProcessMobile = async () => {
    if (mobileData.newMobile && mobileData.newMobile !== '') {
      setMobileData((prevState) => ({
        ...prevState,
        useAlternateMobile: true,
        useSavedMobile: false,
      }));
    }
    return true;
  };

  const steps = [
    {
      id: StepName.ENTER_REGO,
      label: 'Enter Boat Rego',
      description: 'Enter your boat registration number',
      postEvent: fnLoadBoatDetails,
    },
    {
      id: StepName.CONFIRM_MOBILE,
      label: 'Confirm Mobile Number',
      description:
        'We will use this number to send you a reminder if you are late returning',
      postEvent: fnProcessMobile,
    },
    {
      id: StepName.CONFIRM_VESSEL,
      label: 'Confirm Vessel Details',
      description: 'In the event of an incident, it is useful to have the basic details of your boat',
      postEvent: () => true,
    },
    {
      id: StepName.TRIP_DETAILS,
      label: 'Enter Trip Details',
      description: 'Some details about where you are going and when you expect to return',
    },
  ];

  const handleStep = async (action, postEvent) => {
    setLoading(true);

    if (action === StepAction.NEXT) {
      if (await postEvent()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (action === StepAction.PREV) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else if (action === StepAction.RESET) {
      setActiveStep(0);
    }
    setLoading(false);
  };

  const finishStep = async (action) => {
    if (action === StepAction.PREV) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else if (action === StepAction.RESET) {
      setActiveStep(0);
    } else {
      setLoading(true);
      const token = await validateCaptcha();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MERL_API}/trip/register`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
              tkn: requestToken,
              gr: token,
              rego: regoData,
              boat: boatData,
              trip: {
                ...tripData,
                returnDateShort: format(tripData.returnDate, 'yyyy-MM-dd'),
              },
            }),
          },
        );
        const json = await response.json();

        if (json.success) {
          navigate(`/logout?tkn=${requestToken}`);
        }
      } catch (err) {
        setAlertData({
          open: true,
          message: 'Unable to complete registration',
        });
        return false;
      }
    }
    return true;
  };

  function getStepContent(stepNumber) {
    switch (stepNumber) {
      case 0:
        return (
          <EnterRego
            rego={regoData}
            regoValidation={regoValidation}
            stepInfo={steps[stepNumber]}
            loading={loading}
            onChange={handleRegoChange}
            onValidate={handleRegoValidation}
            onNavigate={handleStep}
          />
        );
      case 1:
        return (
          <ConfirmMobile
            mobile={mobileData}
            mobileValidation={mobileValidation}
            requestToken={requestToken}
            stepInfo={steps[stepNumber]}
            onChange={handleMobileChange}
            onValidate={handleMobileValidation}
            onNavigate={handleStep}
          />
        );
      case 2:
        return (
          <VesselDetails
            boat={boatData}
            boatValidation={boatValidation}
            stepInfo={steps[stepNumber]}
            loading={loading}
            onChange={handleBoatChange}
            onValidate={handleBoatValidation}
            onNavigate={handleStep}
          />
        );
      case 3:
        return (
          <TripDetails
            trip={tripData}
            tripValidation={tripValidation}
            stepInfo={steps[stepNumber]}
            loading={loading}
            onChange={handleTripChange}
            onValidate={handleTripValidation}
            onNavigate={finishStep}
          />
        );
      case 4:
        return <Finished />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Trip Registration
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              {getStepContent(activeStep, step)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alertData.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          action={alertAction}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Register;
